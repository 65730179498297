<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column">
        <h4>Interview Details:</h4>
        <table class="w-100">
          <tr>
            <th class="pb-50 w-25">
              <feather-icon icon="InfoIcon" class="mr-75" />
              <span class="font-weight-bold">Title</span>
            </th>
            <td class="pb-50">
              {{ userData.title }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Candidate</span>
            </th>
            <td class="pb-50">
              <b-link
                v-if="$can('candidate_show')"
                :to="{
                  name: 'view-candidate',
                  params: { id: userData.candidate_id },
                }"
              >
                {{ userData.candidate_name }}
              </b-link>
              <p v-else>
                {{ userData.candidate_name }}
              </p>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Time</span>
            </th>
            <td class="pb-50">
              {{ userData.start_at | formatDateTime }} -
              {{ userData.end_at | formatDateTime }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status_label }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Agenda</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.agenta }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Interviewers</span>
            </th>
            <td class="comma-list">
              <span
                v-for="(cnt, index) in userData.interviewers_details"
                :key="index"
                class="item"
              >
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: cnt.hashid },
                  }"
                >
                  {{ cnt.name }}
                </b-link>
                <p v-else>
                  {{ cnt.name }}
                </p>
              </span>
            </td>
          </tr>
          <tr v-if="userData.poster">
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Poster</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-link target="_blank" class="" :href="userData.poster">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-link>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>
