<template>
  <div>
    <feed-back-add
      :interview-id.sync="interviewId"
      :user-data.sync="dataLocal"
      :is-add-new-interview-sidebar-active.sync="isAddNewInterviewSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userInterviewData === undefined">
      <h4 class="alert-heading">Error fetching interview data</h4>
      <div class="alert-body">
        No interview details found with this id. Check
        <b-link class="alert-link" :to="{ name: 'all-interviews' }">
          Interview List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userInterviewData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="8" lg="8" md="8">
          <interview-view-info :user-data="userInterviewData" />
        </b-col>
        <b-col cols="12" md="4" xl="4" lg="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-1 col-8"
            @click="$router.go(-1)"
          >
            <feather-icon icon="ArrowLeftIcon" class="mr-25" />
            <span>Back To list</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            v-if="userInterviewData.video_conferencing == 'zoom'"
            :href="userInterviewData.join_url"
            target="_blank"
            rel="noreferrer noopener"
            class="mb-1 col-8"
          >
            <feather-icon icon="VideoIcon" class="mr-25" />
            <span> Enter Zoom Room </span>
          </b-button>
          <b-button
            v-if="userInterviewData.resume_file_path"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            class="mb-1 col-8"
            @click.stop.prevent="openWindow(userInterviewData.resume_file_path)"
          >
            <feather-icon icon="EyeIcon" class="mr-25" />
            <span>View Resume</span>
          </b-button>
          <div v-if="userInterviewData.task" class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="mb-1 col-8"
              @click.stop.prevent="openWindow(userInterviewData.task)"
            >
              <feather-icon icon="DownloadIcon" />
              <span>Download Task</span>
            </b-button>
          </div>
          <b-button
            v-if="
              userInterviewData.auth_user_can_add_feedback &&
              !userInterviewData.feedback_submitted
            "
            variant="outline-primary"
            @click="writeFeedback()"
            class="mb-1 col-8"
          >
            <feather-icon icon="MessageSquareIcon" class="mr-25" />
            <span>Add Feedback</span>
          </b-button>
          <b-button
            v-if="
              userInterviewData.auth_user_can_add_feedback &&
              !userInterviewData.feedback_submitted
            "
            variant="outline-primary"
            @click="candidateDidNotJoin()"
            class="mb-1 col-8"
          >
            <feather-icon icon="MessageSquareIcon" class="mr-25" />
            <span>Candidate did not join</span>
          </b-button>
          <b-button
            v-if="
              userInterviewData.auth_user_can_add_feedback &&
              userInterviewData.feedback_submitted
            "
            variant="outline-primary"
            @click="writeFeedback()"
            class="mb-1 col-8"
          >
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>Edit Feedback</span>
          </b-button>
        </b-col>
      </b-row>
      <interview-view-timeline :reviews-data="userInterviewData.reviews" />
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink, BButton, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import interviewStoreModule from "../interviewStoreModule";
import InterviewViewInfo from "./InterviewViewInfo.vue";
import InterviewViewTimeline from "./InterviewViewTimeline.vue";
import FeedBackAdd from "@/views/interviews/assigned/list/FeedBackAdd.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    InterviewViewInfo,
    InterviewViewTimeline,
    FeedBackAdd,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userInterviewData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-interviews";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, interviewStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-interviews/fetchInterview", router.currentRoute.params.id)
      .then((response) => {
        userInterviewData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userInterviewData.value = undefined;
        }
      });

    return {
      userInterviewData,
    };
  },
  data() {
    return {
      interviewId: "",
      isAddNewInterviewSidebarActive: false,
      dataLocal: {
        status: false,
        review: "",
      },
      feedback: {
        overall_rating: "Hold",
        review: "Candidate did not join meeting",
        highlight: "",
        technical_skills_rating: "",
        technical_skills_note: "",
        cultural_fit_rating: "",
        cultural_fit_note: "",
        reading_writing_rating: "",
        reading_writing_note: "",
        business_understanding_rating: "",
        business_understanding_note: "",
        feedback_privacy: "",
      },
    };
  },
  created() {
    // get route params from
    this.interviewId = router.currentRoute.params.id;
  },
  mounted() {
    this.isAddNewInterviewSidebarActive = Boolean(
      router.currentRoute.query.isViewInterviewSidebarActive
    );
    if (this.isAddNewInterviewSidebarActive) {
      this.writeFeedback();
    }
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    candidateDidNotJoin() {
      const self = this;
      self.$store
        .dispatch("app-interviews/addFeedback", {
          id: self.interviewId,
          data: this.feedback,
        })
        .then((res) => {})
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Feedback Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
        this.refetchData()
    },
    writeFeedback() {
      const self = this;
      this.$store
        .dispatch("app-interviews/fetchFeedback", this.interviewId)
        .then((res) => {
          self.dataLocal = res.data.data;
          self.dataLocal.viewFeedback = false;
          if (self.dataLocal.review === null) {
            self.dataLocal.review = "";
          }
          if (self.dataLocal.status === null) {
            self.dataLocal.status = "";
          } else if (self.dataLocal.status === 1) {
            self.dataLocal.status = true;
          } else {
            self.dataLocal.status = false;
          }
          this.refetchData();
          self.isAddNewInterviewSidebarActive = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFeedback() {
      this.$store
        .dispatch("app-interviews/fetchFeedback", this.interviewId)
        .then((res) => {
          self.dataLocal = res.data.data;
          self.dataLocal.viewFeedback = false;
          if (self.dataLocal.review === null) {
            self.dataLocal.review = "";
          }
          if (self.dataLocal.status === null) {
            self.dataLocal.status = "";
          } else if (self.dataLocal.status === 1) {
            self.dataLocal.status = true;
          } else {
            self.dataLocal.status = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refetchData() {
      this.$store
        .dispatch(
          "app-interviews/fetchInterview",
          this.$router.currentRoute.params.id
        )
        .then((response) => {
          this.userInterviewData = response.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.userInterviewData = undefined;
          }
        });
    },
  },
};
</script>

<style>
</style>
